var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _vm.$permissionAbility(_vm.permissionsConstant.USERS_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create User ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "line-numbers": true,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row;
        return [props.column.field === 'name' ? _c('span', [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "src": props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.avatar
          }
        })], 1) : _vm._e(), props.column.field === 'status' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant(props.row.status)
          }
        }, [_vm._v(" " + _vm._s(props.row.status ? "Active" : "Inactive") + " ")])], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.permissionsConstant.USERS_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.permissionsConstant.USERS_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-users-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit User' : 'Create User',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "usersValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|max:255")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "name": "name",
            "placeholder": "John Doe"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|email"),
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "email",
            "state": errors.length > 0 ? false : null,
            "name": "email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "status",
      "vid": "status",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "status",
            "options": _vm.statusValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectStatusValue,
            callback: function callback($$v) {
              _vm.selectStatusValue = $$v;
            },
            expression: "selectStatusValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Role",
      "label-for": "role-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "role_id",
      "vid": "role_id",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "role-id",
            "placeholder": "Choose a role",
            "options": _vm.roleIdOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectRoleId,
            callback: function callback($$v) {
              _vm.selectRoleId = $$v;
            },
            expression: "selectRoleId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Designation",
      "label-for": "designation-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "designation_id",
      "vid": "designation_id",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "id": "designation-id",
            "placeholder": "Choose a designation",
            "options": _vm.designationIdOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectDesignationId,
            callback: function callback($$v) {
              _vm.selectDesignationId = $$v;
            },
            expression: "selectDesignationId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gender",
      "label-for": "gender"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "gender",
      "vid": "gender"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          attrs: {
            "id": "gender",
            "placeholder": "Choose  Employee Types",
            "options": _vm.genderOption,
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          model: {
            value: _vm.selectGenderId,
            callback: function callback($$v) {
              _vm.selectGenderId = $$v;
            },
            expression: "selectGenderId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password",
      "label-for": "password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|min:6"),
      "vid": "password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "password",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldType,
            "name": "user-password",
            "placeholder": "············"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          },
          on: {
            "click": _vm.togglePasswordVisibility
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIcon
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Confirm Password",
      "label-for": "password_confirmation"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password_confirmation",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|confirmed:password"),
      "vid": "password_confirmation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "password_confirmation",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldType,
            "name": "password_confirmation",
            "placeholder": "············"
          },
          model: {
            value: _vm.password_confirmation,
            callback: function callback($$v) {
              _vm.password_confirmation = $$v;
            },
            expression: "password_confirmation"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.isUserFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "secondary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }